<template>
  <a-modal v-model="show" title="车辆分类设置" :width="600" :footer="null" @cancel="() => {
    $emit('success');
    show = false;
  }
    " centered destroyOnClose>
    <a-table :scroll="{ x: '100%',y:'300px' }" :style="{ background: '#FFF' }" size="small" :columns="keys" row-key="categoryId"
             ref="list" :data-source="category" :loading="loading" :bordered="false" :pagination="false">
      <!--名称-->
      <template slot-scope="text, row, index" slot="name">
        <a-input v-model="row.categoryName" v-if="row.edit" @pressEnter="confirmRow(row, index)"></a-input>
        <span v-else>{{ row.categoryName }}</span>
      </template>

      <!--操作-->
      <template slot-scope="text, row, index" slot="action">
        <a-button-group shape="circle" size="small">
          <a-tooltip title="编辑" v-if="!row.edit">
            <a-button icon="edit" @click="editRow(row, index)"></a-button>
          </a-tooltip>
          <a-tooltip title="保存" v-else>
            <a-button icon="check" @click="confirmRow(row, index)"></a-button>
          </a-tooltip>
          <a-tooltip title="取消" v-if="row.edit"><a-button icon="close" @click="delRow(row, index)"></a-button></a-tooltip>
          <a-tooltip title="删除" v-else><a-button icon="delete" @click="delRow(row, index)"></a-button></a-tooltip>
        </a-button-group>
      </template>
      <template slot="footer">
        <div class="footer">
          <a-button type="primary" @click="addRow" size="small" icon="plus">添加新分类</a-button>
        </div>
      </template>
    </a-table>
  </a-modal>
</template>

<script>
import request from '@/config/request';
import utils from '@/common/utils';
import apiUtil from '@/common/apiUtil';
export default {
  data() {
    return {
      show: false,
      loading: false,
      organId: apiUtil.getOrganId(),
      category: [],
      editname: [],
      keys: [
        { title: '分类名称', scopedSlots: { customRender: 'name' }, width: 260, ellipsis: true },
        { title: '操作', scopedSlots: { customRender: 'action' }, align: 'center', width: 200 }
      ]
    };
  },
  methods: {
    ...apiUtil,
    getList() {
      this.loading = true;
      if (this.organId == 0) {
        this.organId = apiUtil.getDefaultOrganId();
      }
      this.http
        .get(`/platform/CarCategory/list`, { organId: this.organId })
        .then(ret => {
          ret.data.forEach(item => (item.edit = false));
          this.category = ret.data;
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
        });
    },
    addRow() {
      const item = {
        categoryId: '',
        categoryName: '',
        organId: this.organId,
        edit: true
      };
      this.category.push(item);
    },
    delRow(row, index) {
      if (!row.categoryId) {
        this.category.splice(index, 1);
      }
      if (row.edit) {
        row.edit = false;
        row.categoryName = this.editname;
        return;
      }
      utils.confirm('您确认要删除此分类吗?').then(() => {
        utils.showSpin();
        request.get('/platform/CarCategory/delete', { categoryId: row.categoryId }).then(ret => {
          this.getList();
        });
      });
    },
    editRow(row, index) {
      row.edit = true;
      this.editname = row.categoryName;
    },
    confirmRow(row, index) {
      utils.showSpin();
      // console.log(row);
      request.post('/platform/CarCategory/save', row).then(ret => {
        row.edit = false;
        this.getList();
      });
    },
    _show() {
      this.show = true;
      this.getList();
    },
    _close() {
      console.log(1);
      this.show = false;
    }
  }
};
</script>
<style>
.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>
